"use client"
import { ConfigProvider, message, notification } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import React, { ReactNode, SetStateAction, createContext } from "react";
import { COLOR_PRIMARY } from "../utils/common";
import { MeetingManager, useMeetingManager, useUniqueId } from "amazon-chime-sdk-component-library-react";
import { BloxApi } from "../utils/BloxApi";
import { ConsoleLogger, DefaultEventController, LogLevel, MeetingSessionConfiguration, NoOpEventReporter } from "amazon-chime-sdk-js";

type ToastFunction = (msg: any) => void;

interface CommonContextType {
  loading: boolean;
  antNotificationApi: NotificationInstance;
  setLoading: React.Dispatch<SetStateAction<boolean>>;
  openNotification: (message: string, description: React.ReactNode) => {
    info: () => void,
    success: () => void,
    warning: () => void,
    error: () => void,
  }
  Toast: {
    error: ToastFunction;
    success: ToastFunction;
    warning: ToastFunction;
  };
  meetingManager:MeetingManager
}

export const GlobalContext = createContext({} as CommonContextType);

type GlobleContextProviderProps = {
  children: ReactNode;
};

const GlobalProvider = (props: GlobleContextProviderProps) => {

  const [antNotificationApi, notificationContextHolder] = notification.useNotification();
  const [messageApi, contextHolder] = message.useMessage();

  const meetingManager = useMeetingManager();
  const uniqueId = useUniqueId()

  const [loading, setLoading] = React.useState(false);

  const scrollToTop = () => {
    if (window) {
      window.scrollTo(0, 0);
    }
  };
  const openNotification = (message: string, description: React.ReactNode) => ({
    info: () =>
      antNotificationApi.info({
        message,
        description,
        placement: "bottomRight",
      }),
    success: () =>
      antNotificationApi.success({
        message,
        description,
        placement: "bottomRight",
      }),
    warning: () =>
      antNotificationApi.warning({
        message,
        description,
        placement: "bottomRight",
      }),
    error: () =>
      antNotificationApi.error({
        message,
        description,
        placement: "bottomRight",
      }),

  })

  const success = (success: any) => {
    messageApi.open({
      type: "success",
      content: success,
    });
  };

  const error = (error: any) => {
    if (typeof error == "string") {
      messageApi.open({
        type: "error",
        content: error
      })
    } else {
      let errorBody = error?.response?.body;
      let message = errorBody?.message;
      let error_message = errorBody?.error_description;
      messageApi.open({
        type: "error",
        content: message
          ? message
          : typeof error_message == "string"
            ? error_message
            : error_message
              ? JSON.stringify(error_message)
              : JSON.stringify(error),
        duration: 3,
      });
    }
    setTimeout(messageApi.destroy, 1000);
  };

  const warning = (warning: any) => {
    messageApi.open({
      type: "warning",
      content: warning,
    });
  };



  const Toast = {
    success,
    warning,
    error,
  };


  React.useEffect(scrollToTop, []);

  return (
    <GlobalContext.Provider
      value={{
        loading,
        setLoading,
        openNotification,
        antNotificationApi,
        Toast,
        meetingManager,
        ...props,
      }}
    >
      <ConfigProvider

        theme={{
          token: {
            colorPrimary: COLOR_PRIMARY,
            // fontSize: 12
          },
          // components: {
          //   Progress: {
          //     colorSuccess: props.colorPrimary
          //   },
          //   Table: {
          //     headerBg: COLOR_LIGHT_GRAY
          //   },

          //   Collapse: {
          //     headerBg: COLOR_PRIMARY,
          //     colorTextHeading: COLOR_WHITE
          //   },
          //   Radio: {
          //     colorPrimary: COLOR_RADIO_BUTTON,
          //     colorPrimaryHover: COLOR_RADIO_BUTTON
          //   },
          //   Descriptions: {
          //     labelBg: COLOR_LIGHT_RED
          //   }
          // },
        }}
      >

        {notificationContextHolder}
        {props.children}
        {contextHolder}
      </ConfigProvider>

    </GlobalContext.Provider>
  );
}

export default GlobalProvider;
