import { InvokeCommand, InvokeCommandInput, LambdaClient } from "@aws-sdk/client-lambda";

type IPayloadResponse = {
    statusCode: number,
    headers: any,
    body: any
}
export class BloxApi {
    private lambda: LambdaClient

    constructor() {
        this.lambda = new LambdaClient({
            region: process.env.REACT_APP_AWS_REGION,
            credentials: {
                accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID as string,
                secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY as string,
            }
        });
    }

    private invokeLambda = async (cmd: InvokeCommandInput) => {
        try {
            const command = new InvokeCommand(cmd);
            const response = await this.lambda.send(command);

            // Convert Uint8Array to string
            const decoder = new TextDecoder('utf-8');
            const payloadString = decoder.decode(response.Payload);

            // If the response is JSON, parse it
            const payloadJson: IPayloadResponse = JSON.parse(payloadString);

            return payloadJson.body
        } catch (error) {
            console.error(error);
        }
    };

    async testFunction() {
        const params = {
            FunctionName: 'test',
            Payload: JSON.stringify({ key1: 'value' })
        };
        return await this.invokeLambda(params)
    }
    async createMeeting(name: string, uniqueId: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'blox-chime-test',
            InvocationType: "RequestResponse",

            Payload: JSON.stringify({
                "body": {
                    "type": 1,
                    "externalMeetingId": name + "-" + uniqueId,
                    "mediaRegion": "eu-west-1",
                    "clientRequestToken": uniqueId,
                    "meetingFeatures": {
                        "audio": {
                            "echoReduction": "AVAILABLE"
                        },
                        "video": {
                            "maxResolution": "HD"
                        },
                        "content": {
                            "maxResolution": "FHD"
                        }
                    }
                }

            }),

        };
        return await this.invokeLambda(params)
    }
    async joinMeeting(meetingId: string, name: string, uniqueId: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'blox-chime-test',
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
                "body": {
                    "type": 2,
                    "meetingId": meetingId,
                    "externalUserId": name + "-" + uniqueId,
                }

            }),

        };
        return await this.invokeLambda(params)
    }
    async endMeeting(meetingId?: string|null) {
        const params: InvokeCommandInput = {
            FunctionName: 'blox-chime-test',
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
                "body": {
                    "type": 3,
                    ...(meetingId && {meetingId})
                }

            }),

        };
        return await this.invokeLambda(params)
    }
    async meetingDetails(meetingId: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'blox-chime-test',
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
                "body": {
                    "type": 4,
                    "meetingId": meetingId,
                }

            }),

        };
        return await this.invokeLambda(params)
    }
    async attendeeList(meetingId: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'blox-chime-test',
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
                "body": {
                    "type": 6,
                    "meetingId": meetingId,
                }

            }),

        };
        return await this.invokeLambda(params)
    }
    async logoutAttendee(meetingId: string, attendeeId: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'blox-chime-test',
            InvocationType: "RequestResponse",
            Payload: JSON.stringify({
                "body": {
                    "type": 7,
                    "meetingId": meetingId,
                    attendeeId
                }

            }),

        };
        return await this.invokeLambda(params)
    }
    async submitIdCard(base64: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'ai-lambda-test',
            // InvocationType:"RequestResponse",
            Payload: JSON.stringify({
                "type": 8,
                "img": base64
            }),

        };
        return await this.invokeLambda(params)
    }
    async compareImages(img: string, imgTarget: string) {
        const params: InvokeCommandInput = {
            FunctionName: 'ai-lambda-test',
            // InvocationType:"RequestResponse",
            Payload: JSON.stringify({
                "type": 9,
                img: img,
                imgTarget: imgTarget,
            }),

        };
        return await this.invokeLambda(params)
    }
}