import { AudioInputControl, AudioOutputControl, Camera, ContentShareControl, ControlBar, ControlBarButton, DeviceLabels, Dialer, Laptop, LocalVideo, Microphone, Phone, PreviewVideo, RemoteVideo, RemoteVideos, Rooms, Roster, RosterCell, RosterGroup, RosterHeader, Sound, useActiveSpeakersState, useContentShareControls, useLocalVideo, useRemoteVideoTileState, useToggleLocalMute, VideoGrid, VideoInputBackgroundBlurControl, VideoTile, VideoTileGrid } from "amazon-chime-sdk-component-library-react";
import { useNavigate, useParams } from "react-router-dom";
import { CopyOutlined } from "@ant-design/icons";

const ViewMeeting = () => {
  const { tiles, attendeeIdToTileId, size, tileIdToAttendeeId } = useRemoteVideoTileState();

  const navigate = useNavigate()
  const params = useParams()

  const hangUpButtonProps = {
    icon: <Phone />,
    onClick: () => {
      console.log('End meeting')
      window.location.reload()
    },
    label: 'End',
  };

  const clipboardButtonProps = {
    icon: <Rooms />,
    onClick: () => {
      navigator.clipboard.writeText(window.origin + "?meetingId=" + params.meetingId)
    },
    label: 'Copy',
  };

  // const videos = tiles.map((tileId) => <RemoteVideo tileId={tileId} />);


  return <div style={{ height: '100vh', boxSizing: 'border-box' }} >
    
    <VideoGrid layout="featured" >
      {/* <LocalVideo nameplate="Self" /> */}

      {/* <RemoteVideos /> */}
      {/* <RemoteVideo tileId={2} /> */}

      <VideoTileGrid
        layout={"featured"} />
      {/* {videos} */}
    </VideoGrid>

    <ControlBar showLabels layout="top">


      <ControlBarButton {...clipboardButtonProps} />
      <AudioInputControl />
      <AudioOutputControl />
      <VideoInputBackgroundBlurControl />
      <ContentShareControl />
      <ControlBarButton {...hangUpButtonProps} />
    </ControlBar>


    {/* <Roster>
      <RosterHeader
        title="Present"
        badge={2}
        onClose={() => { }}
        searchValue="Michael"
        onSearch={() => { }}
      />
      <RosterGroup>
        {tiles.map((res) =>
          <RosterCell
            name={res.toString()}
            subtitle="Regional Manager"
            muted={false}
            videoEnabled={true}
          />)}

      </RosterGroup>

    </Roster> */}

  </div>
};

export default ViewMeeting;
