import { Grid } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { GlobalContext } from "../provider/Provider";
import { BloxApi } from "../utils/BloxApi";
import { DeviceLabels, MeetingManagerJoinOptions, useUniqueId } from "amazon-chime-sdk-component-library-react";
import { ConsoleLogger, DefaultEventController, LogLevel, MeetingSessionConfiguration, NoOpEventReporter } from "amazon-chime-sdk-js";
const { useBreakpoint } = Grid;

function CreateMeeting() {
  const bloxApi = new BloxApi()

  const { meetingManager } = useContext(GlobalContext)

  const screens = useBreakpoint();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const uniqueId = useUniqueId()


  const [code, setCode] = useState(searchParams.get("meetingId")||"");
  const [name, setName] = useState("");


  const joinMeeting = async (query: {
    name: string,
    creator: boolean,
    meetingId?: string
  }) => {
    // Fetch the meeting and attendee data from your server application

    let manageRes = {
      Meeting: {
        MeetingId: ""
      },
      Attendee: {
        AttendeeId: ""
      },
    };
    if (query.creator && query.meetingId) {
      const meetingRes = await bloxApi.meetingDetails(query.meetingId);
      const attendeeRes = await bloxApi.joinMeeting(
        query.meetingId,
        query.name,
        uniqueId
      );
      manageRes.Meeting = meetingRes.Meeting;
      manageRes.Attendee = attendeeRes.Attendee;

    } else if (query.creator) {
      const meetingRes = await bloxApi.createMeeting(query.name, uniqueId);
      console.log("meetingRes", meetingRes);

      const attendeeRes = await bloxApi.joinMeeting(
        meetingRes.Meeting.MeetingId || "test",
        query.name,
        uniqueId
      );
      manageRes.Meeting = meetingRes.Meeting;
      manageRes.Attendee = attendeeRes.Attendee;
    } else {
      const meetingRes = await bloxApi.meetingDetails(query.meetingId!);
      const attendeeRes = await bloxApi.joinMeeting(
        query.meetingId || "test",
        query.name,
        uniqueId
      );
      manageRes.Meeting = meetingRes.Meeting;
      manageRes.Attendee = attendeeRes.Attendee;
    }
    console.log("manageRes", manageRes);


    const meetingSessionConfiguration = new MeetingSessionConfiguration(manageRes.Meeting, manageRes.Attendee);
    const eventController = new DefaultEventController(
      meetingSessionConfiguration,
      new ConsoleLogger('Chime SDK', LogLevel.INFO),
      new NoOpEventReporter()
    );

    const options: MeetingManagerJoinOptions = {
      deviceLabels: DeviceLabels.AudioAndVideo,
      eventController
    }

    // const deviceLabels = async () => {
    //   // Customize your own behavior here

    //   const stream = await navigator.mediaDevices.getUserMedia({
    //     video: true,
    //   });

    //   // Customize your own behavior here
    //   return stream;
    // };
    // const options = {
    //   deviceLabels,
    //   eventController
    // };

    // Use the join API to create a meeting session using the above data
    await meetingManager.join(
      meetingSessionConfiguration,
      options
    );

    // Skip devices setup

    // Start the session to join the meeting
    await meetingManager.start();


    navigate(`/meeting/${manageRes.Meeting.MeetingId}?name=` + name + "&viewer=1", { state: { name: name, viewer: 1, ss: 1, otp: 0, ai: 0, vCount: 1, control: 1, copyLink: 1 } });
  };

  // const joinMeeting = async () => {
  //   if (code) {
  //     navigate("/grid/" + code + "?name=" + name + "&viewer=" + searchParams.get("viewer") + "&id" + searchParams.get("id"));
  //   }
  // };

  const handleCode = (url: string) => {
    if (url.includes("http")) {
      const strArr = url.split("/");
      const code = strArr[strArr.length - 1];
      setCode(code);
    } else {
      setCode(url);
    }
  };
  const handleName = (event: any) => {
    // Prevent the default form submission behavior
    event.preventDefault();

    // Access the form element using event.target
    var form = event.target;

    // Access the input element by its name
    var inputValue = form.elements["name"].value;
    setName(inputValue);
  };

  return (
    <div
      className={`flex flex-col ${screens["sm"] ? "" : "w-[80vw] ml-10"
        } justify-center items-center h-screen`}
    >
      <h1 className="font-semiboldbold text-5xl  mb-10">
        Video calls and meetings for everyone
      </h1>
      <p className=" text-lg text-gray-400 max-w-xl mb-10">
        This Meeting provides secure, easy-to-use video calls and meetings for
        everyone, on any device.
      </p>
      {name ? (
        <div className="flex gap-10 items-center flex-wrap text-white">
          {/* <select
          className="px-5 py-3 bg-blue-400 outline-none rounded-md"
          onChange={(e) => createMeeting(e.target.value)}
        >
          <option value="Option 1">Create Meeting</option>
          <option value="instant">Instant Meeting</option>
        </select> */}
          <button
            className=" border-black border-2 px-10 py-3 rounded-lg focus-visible:outline-blue-500 text-black bg-500"
            onClick={() => joinMeeting({ name: "test", creator: true, })}
          >
            Create Meeting
          </button>

          <form
            onSubmit={(e) => {
              console.log("e", e);
              e.preventDefault();
              joinMeeting({ name: "att", creator: false, meetingId: code });
            }}
            className={`${screens["sm"] ? "" : "-mt-7"}`}
          >
            <div className="flex gap-5">
              <input
                autoFocus
                type="text"
                className=" border-black border-2 pl-12 px-2 py-3 rounded-lg focus-visible:outline-blue-500 text-black"
                placeholder="Enter a code or link"
                value={code}
                onChange={(e) => handleCode(e.target.value)}
              />
              <button className="text-blue-500">Join</button>
            </div>
          </form>
        </div>
      ) : (
        <form onSubmit={handleName}>
          <span className="absolute mx-3 my-2.5"></span>
          <div className="flex gap-2 flex-wrap">
            <input
              autoFocus
              type="text"
              name="name"
              required
              className=" border-black border-2 pl-12 px-2 py-3 rounded-lg focus-visible:outline-blue-500 text-black"
              placeholder="Enter your name"
            />
            <button
              type="submit"
              className=" border-black border-2 px-10 py-3 rounded-lg focus-visible:outline-blue-500 text-black bg-500"
            >
              Submit
            </button>
          </div>
        </form>
      )}
    </div>
  );
}

export default CreateMeeting;
