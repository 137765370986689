export const NEXT_PUBLIC_PRIMARY_COLOR="#c62c2b"
export const NEXT_PUBLIC_PRIMARY_GRAY="#585858"
export const COLOR_PRIMARY="#c62c2b"
export const COLOR_SUCCESS="#28a745"
export const COLOR_WARNING="#ffc107"
export const COLOR_WHITE="#ffffff"
export const COLOR_RADIO_BUTTON="#1677ff"

export const COLOR_LIGHT_BLUE="#bae0ff"
export const COLOR_LIGHT_GREEN="#d9f7be"
export const COLOR_LIGHT_RED="#fff1f0"
export const COLOR_LIGHT_ORANGE="#ffe7ba"
export const COLOR_LIGHT_YELLOW="#ffffb8"
export const COLOR_LIGHT_GRAY="#f5f5f5"
export interface IEkey {
    userId: string,
    status: string,
    lastLogin: string,
    lastFailed: string,
    registrationType: string,
    authId: string,
    authIdTimestamp: string,
    nameEN: string,
    nameAR: string,
    eserviceID: string,
    sessionID: string,
    corpPassRoleDeails: string,
}

export const ACCESS_TOKEN="access_token"