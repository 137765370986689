import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import GlobalProvider from "./provider/Provider";
import CreateMeeting from "./components/CreateMeeting";
import GridView from "./components/ViewMeeting";
import { Fragment } from "react/jsx-runtime";
import { BackgroundBlurProvider, GlobalStyles, lightTheme, MeetingProvider } from "amazon-chime-sdk-component-library-react";
import { ThemeProvider } from 'styled-components';

function App() {

  return (
    <Fragment>
      <BrowserRouter>
        <ThemeProvider theme={lightTheme}>
          <GlobalStyles />
          <BackgroundBlurProvider>
            <MeetingProvider>
              <GlobalProvider>
                <Routes>
                  <Route index element={<CreateMeeting />} />
                  <Route path="meeting/:meetingId" element={<GridView />} />
                </Routes>
              </GlobalProvider>
            </MeetingProvider>
          </BackgroundBlurProvider>
        </ThemeProvider>

      </BrowserRouter>
    </Fragment>

  );
}

export default App;
